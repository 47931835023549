<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">{{ date | relativeDate }}</span>
    </template>
    <span>{{ (date * 1000) | actualDate }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    date: {
      type: Number,
      required: true,
    },
  },
};
</script>
