var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm._f("relativeDate")(_vm.date)))])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm._f("actualDate")(_vm.date * 1000)))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }